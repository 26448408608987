$w-blue:#2748f9;
$w-light-grey: #636363;
$w-dark-grey: #3b3b3b;
$w-background: #fbfafa;
$w-lighter-grey: #c3c3c3;

$w-blue-light: rgb(33,176,230);
$w-blue-lighter: rgb(228,247,248);
$w-dark-red: #F44336;
$w-dark-green: #4caf50;
$w-light-green: #4FE6A1;

$w-disabled: rgba(0, 0, 0, 0.6);

/* You can add global styles to this file, and also import other style files */
@import './variables';
@import './material-icons.scss';
@import './customer.theme.scss';
// @import '/node_modules/css-star-rating/scss/star-rating';

html, body {
  margin: 0;
  height: 100%;
  background-color: $w-background;
}

.mat-input-element:disabled{
  color: $w-disabled !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: $w-disabled !important;
}

.mat-select-disabled .mat-select-value {
  color: $w-disabled!important;
}

.mat-form-field.mat-form-field-disabled {
  .mat-form-field-prefix, .mat-form-field-suffix {
    color: $w-disabled !important;
  }
}

.mat-toolbar.mat-primary {
  background: #fff;
  color: black;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  background-color: #fff;
}

.menu-button{
  border-radius: 0px !important;
  line-height: 64px !important;
  color: $w-light-grey;
}

.menu-active{
  box-shadow: inset 0 -5px $w-blue;
}

.button-icon {
  margin-right: 5px !important;
}

.button-blue{
  background-color: $w-blue !important;
  color: #fff !important;
}

.button-blue:disabled{
  background-color: $w-light-grey !important ;
  color: #fff !important;
  cursor: not-allowed !important;
}

.button-red{
  background-color: $w-dark-red !important;
  color: #fff !important;
}

.blue-color {
  color: $w-blue !important;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.content-header {
  width: 100%;

  .title {
    float: left;
    padding-left: 1rem;
    margin-top: 1rem;
  }

  .title-settings {
    float: left;
    padding-left: 1rem;
    margin-top: 0;
  }

  .actions {
    float: right;
    padding-right: 1rem;
    margin-top: 2rem;
  }
}

.content{
  position: relative;
  top: 64px;
  z-index: 2;
}

.no-margin-bottom{
  margin-bottom: 0px !important;
}

.margin-bottom-1 {
  margin-bottom: 1rem !important;
}

.margin-left-1 {
  margin-left: 1rem !important;
}

.margin-right-3 {
  margin-right: 3rem !important;
}

.margin-right-2 {
  margin-right: 1rem !important;
}

.margin-top-1 {
  margin-top: 1rem !important;
}

.margin-top-2 {
  margin-top: 2rem !important;
}

.padding-1 {
  padding: 0.5rem;
}

.padding-top-2 {
  padding-top: 2rem !important;
}

.padding-top-1 {
  padding-top: 1rem !important;
}

.padding-bottom-1 {
  padding-bottom: 1rem !important;
}

.vertical-middle {
  vertical-align: middle;
}

.text-right {
  text-align: right;
}

.full-width {
  width: 100%;
}

.snackbar-info{
  background-color: $w-dark-green;
  font-weight: bold;
  span {
    margin:auto;
    text-align: center;
  }
}

.snackbar-error{
  background-color: $w-dark-red;
  font-weight: bold;
  span {
    margin:auto;
    text-align: center;
  }
}

.state {
  width:15px;
  height:15px;
  border-radius:50px;
  margin-left: 12%;
}

.done-state {
  @extend .state;
  background-color:#4FE6A1;
  box-shadow: 0 0 8px #4FE6A1, inset 0 0 8px #4FE6A1;
}

.planned-state {
  @extend .state;
  background-color:#3BCAFF;
  box-shadow: 0 0 8px #3BCAFF, inset 0 0 8px #3BCAFF;
}

.pending-state {
  @extend .state;
  background-color:#FFEE00;
  box-shadow: 0 0 8px #FFEE00, inset 0 0 8px #FFEE00;
}

.canceled-state {
  @extend .state;
  background-color:#F23B30;
  box-shadow: 0 0 8px #F23B30, inset 0 0 8px #F23B30;
}

.to-plan-state {
  @extend .state;
  background-color:#0922F9;
  box-shadow: 0 0 8px #0922F9, inset 0 0 8px #0922F9;
}

.confirmed-state {
  @extend .state;
  background-color:#329932;
  box-shadow: 0 0 8px #329932, inset 0 0 8px #329932;
}

//settings

.settings-menu-container {
  margin-top: 25px;
}

.settings-menu {
  min-height: 40px;
  color: $w-dark-grey;
  background-color: #fff;

  a {
    height: 100%;
    width: 100%;
    display: inline-block;
    color: $w-dark-grey;
    text-decoration: none;
  }

  .icon {
    padding-top: 8px;
    padding-left: 1em;
  }

  .text {
    padding-top: 8px;
  }
}

.settings-menu-active {
  background-color: $w-blue-lighter;
  color: $w-blue-light !important;
  box-shadow: inset 4px 0 $w-blue-light;
}

.language-radio-group {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
}

.language-radio-button {
  margin: 5px;
}

.my-account {
  padding-left: 1rem;

  .mat-card-header {
    .mat-card-header-text {
      margin-left: 0px !important;
    }
  }
}

// style for a better rending for ratings stars in reports's page
.rating {
  margin-bottom : 0px;
  justify-content : initial;
  outline: 0;
}

.star-container {
  margin-left: 0px;
  margin-right: 0px;
}

h1 {
}


.transport-card {
  .mat-card-header-text{
    margin-top: 0.4rem;
  }
}

.total-orders-card {
  float: right;
  margin-bottom: 5px;
  padding: 0 !important;
  .card-title {
    font-size: 15px;
  }
  h1 {
    margin-top:0 !important;
    margin-bottom:0 !important;
  }
}

.chart-orders-done-card {
  margin-top: 50px;
  margin-bottom: 5px;
  .card-title {
    font-size: 15px;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
